* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #104a92;
  // background-image: url(https://www.freevector.com/uploads/vector/preview/23966/DD-Space-Background-44012-Preview.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

svg {
  width: 100%;
  /* height: 100%; */
}

circle,
path,
line,
polygon {
  vector-effect: non-scaling-stroke;
}

path {
  cursor: pointer;
  stroke: black;
  stroke-width: 1px;
  fill: #555;
}

#grid {
  circle,
  line {
    fill: none;
    stroke: #0d59ff;
    stroke-width: 0.5px;
  }
}

.dots circle {
  cursor: pointer;
  fill: black;
}

.dots circle:hover {
  fill: yellow;
}

.trash-fence {
  fill: none;
  stroke-width: 1px;
  stroke: #a8abae;
}

#roads {
  path,
  line,
  circle {
    vector-effect: none;
    fill: none;
    stroke-width: 25px;
    stroke: #ccc;
  }
}

circle.plaza {
  fill: #ccc;
  stroke: none;
}

#container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100vh;
  #map-container,
  #sidebar {
    min-height: 100%;
    overflow: scroll;
  }
  #map-container {
    padding: 50px 0 0;
    flex: 1;
  }
  #sidebar {
    padding: 20px;
    width: 100%;
    max-width: 400px;
    height: 100%;
    background: #242121;
    color: white;
  }
}

table {
  color: white;
  border-collapse: collapse;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  th {
    font-weight: normal;
    text-align: left;
    padding: 5px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 1em 0;
}

h1 {
  margin-top: 0;
}

p {
  color: rgba(255, 255, 255, 0.5);
  strong,
  a {
    font-weight: normal;
    color: rgba(255, 255, 255, 0.7);
  }
}
